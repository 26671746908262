<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="pb-0 mt-6">
                <h3 class="title ml-1 font-weight-bold">Add Author Request</h3>
            </v-col>

            <v-col cols="12" class="pt-0">
                <stepper ref="stepper" :steps="steps" @change="onStepChange">
                    <!-- get started -->
                    <v-stepper-content
                        class="wizard__content--item"
                        step="getStarted"
                    >
                        <get-started
                            ref="getStartedStep"
                            :companies="companies"
                            @next="onNext"
                            @companySaved="onCompanySaved"
                        />
                    </v-stepper-content>

                    <!-- media -->
                    <v-stepper-content
                        class="wizard__content--item"
                        step="media"
                    >
                        <media
                            v-if="currentStep.step === 'media'"
                            @next="onNext"
                            @prev="onPrev"
                        />
                    </v-stepper-content>

                    <!-- finishing up -->
                    <v-stepper-content
                        class="wizard__content--item"
                        step="finishingUp"
                    >
                        <finishing-up
                            :categories="categories"
                            :credits="credits"
                            :premium-credits="premiumCredits"
                            :financial-credits="financialCredits"
                            :msn-credits="msnCredits"
                            :checking-payment="checkingPayment"
                            :is-active="currentStep.step === 'finishingUp'"
                            @premiumChange="dataRequiredForPremium"
                            @next="onNext"
                            @prev="onPrev"
                        />
                    </v-stepper-content>
                </stepper>
            </v-col>
        </v-row>

        <confirm-dialog
            ref="confirmDialog"
            title="Unsaved changes"
            continue-button-text="Continue editing"
        >
            <a-alert type="warning" :dense="false">
                <p class="ma-0">
                    If you wish to save your author request, please complete the
                    Get Started step.
                </p>
            </a-alert>
        </confirm-dialog>
    </v-container>
</template>

<script lang="ts">
import { ProvideReactive, Watch } from '@/utils/decorators';
import Vue from 'vue';
import Component from 'vue-class-component';
import { NavigationGuardNext, Route } from 'vue-router';

import { convertToArray } from '@/utils/helpers';

import { AAlert } from '@/components/AAlert';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { Modal } from '@/components/Modal';

import { Step, Stepper } from '@/components/Stepper';
import { FinishingUp, GetStarted, Media } from './Steps';

import type {
    AuthorRequestData,
    AutorRequestDescription
} from '@/types/AuthorRequest';
import type { Company } from '@/types/Company';
import type { Category } from '@/types/Category';
import type { ServerResponse } from '@/types/ServerResponse';

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        Stepper,
        GetStarted,
        Media,
        FinishingUp,
        Modal,
        AAlert,
        ConfirmDialog
    }
})
export default class Create extends Vue {
    $refs!: {
        stepper: InstanceType<typeof Stepper>;
        getStartedStep: InstanceType<typeof GetStarted>;
        confirmDialog: InstanceType<typeof ConfirmDialog>;
    };

    description: AutorRequestDescription = {
        theme: '',
        details: '',
        links: ''
    };

    @ProvideReactive('requestData')
    request: Partial<AuthorRequestData> = {
        description: this.description,
        is_premium: false,
        is_financial: false
    };

    steps: Step[] = [
        {
            title: 'Get Started',
            step: 'getStarted',
            completed: false,
            editable: false
        },
        {
            title: 'Media',
            step: 'media',
            completed: false,
            editable: false
        },
        {
            title: 'Finishing Up',
            step: 'finishingUp',
            completed: false,
            editable: false
        }
    ];

    currentStep: Step = this.steps[0];

    companies: Pick<Company, 'id' | 'name'>[] = [];

    categories: Pick<Category, 'id' | 'name'>[] = [];

    credits = 0;

    premiumCredits = 0;

    financialCredits = 0;

    msnCredits = 0;

    paymentLink = null;

    showAlert = false;

    moveAwayPath = '';

    checkPaymentInterval: ReturnType<typeof setInterval> | null = null;

    checkingPayment = false;

    get purchaseCreditsNecessaryToContinue() {
        return (
            !this.credits ||
            (this.request.is_premium && !this.premiumCredits) ||
            (this.request.is_financial && !this.financialCredits) ||
            (this.request.is_msn && !this.msnCredits)
        );
    }

    get endpoint() {
        return this.$route.fullPath.replace(
            'author-requests',
            'author_requests'
        );
    }

    get loading() {
        return this.$store.state.loading.show;
    }

    @Watch('loading')
    onLoading(loading: boolean) {
        if (this.checkingPayment && !loading) {
            this.$store.dispatch('loading/show');
        }
    }

    mounted() {
        this.getData().then(() => {
            this.setupWizard();
        });
    }

    beforeDestroy() {
        if (this.checkPaymentInterval) {
            clearInterval(this.checkPaymentInterval);
        }
    }

    async getData() {
        return this.$http
            .get<
                ServerResponse<{
                    request: AuthorRequestData;
                    companies: Company[];
                    categories: Pick<Category, 'id' | 'name'>[];
                    credits: number;
                    premium_credits: number;
                    financial_credits: number;
                    msn_credits: number;
                }>
            >(this.endpoint)
            .then(({ data }) => {
                const {
                    companies,
                    categories,
                    credits,
                    premium_credits,
                    financial_credits,
                    msn_credits,
                    request
                } = data.data;

                this.companies = convertToArray(companies);

                this.categories = convertToArray(categories);

                this.credits = credits;

                this.premiumCredits = premium_credits;

                this.financialCredits = financial_credits;

                this.msnCredits = msn_credits;

                if (request) {
                    this.setRequest(request);
                }
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                this.$router.push('/author-requests/list');
            });
    }

    async save() {
        return this.$http
            .post<
                ServerResponse<{
                    request: AuthorRequestData;
                    companies: Company[];
                    credits: number;
                    premium_credits: number;
                    financial_credits: number;
                    msn_credits: number;
                    redirectUrl?: string;
                }>
            >(this.endpoint, this.request)
            .then(({ data }) => {
                if (!this.$route.params.id) {
                    this.$router.push({
                        params: { id: String(data.data.request.id) }
                    });
                }

                return data.data;
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    onNext() {
        const isFinalStep = this.currentStep.step === 'finishingUp';

        if (isFinalStep) {
            this.request.finalStep = true;
        }

        this.save().then(data => {
            if (data) {
                this.premiumCredits = data.premium_credits;

                this.financialCredits = data.financial_credits;

                this.msnCredits = data.msn_credits;

                this.credits = data.credits;

                if (data.request) {
                    this.setRequest(data.request);
                }

                if (isFinalStep) {
                    return this.onFinalStep(data);
                }

                this.$refs.stepper.nextStep();
            }
        });
    }

    onPrev() {
        this.$refs.stepper.prevStep();
    }

    onCompanySaved(company: Company) {
        if (company) {
            this.companies.push({ id: company.id, name: company.name });

            this.$set(this.request, 'company_id', company.id);
        }
    }

    onStepChange(step: Step) {
        this.currentStep = step;
    }

    setupWizard() {
        if (this.$route.query.premium) {
            const isPremium = Boolean(Number(this.$route.query.premium));

            this.$set(this.request, 'is_premium', isPremium);
        }

        if (this.$route.query.financial) {
            const isFinancial = Boolean(Number(this.$route.query.financial));

            this.$set(this.request, 'is_financial', isFinancial);
        }

        if (this.$route.query.msn) {
            const isMsn = Boolean(Number(this.$route.query.msn));

            this.$set(this.request, 'is_msn', isMsn);
        }

        if (Number(this.$route.query.pay)) {
            this.steps = this.steps.map(step => ({
                ...step,
                editable: true,
                completed: true
            }));

            this.$refs.stepper.currentStep = this.steps[2];

            this.checkingPayment = true;

            this.checkPaymentInterval = setInterval(this.checkPayment, 5000);
        }
    }

    checkPayment() {
        this.$http
            .get(this.endpoint)
            .then(({ data }) => {
                const {
                    credits,
                    premium_credits,
                    financial_credits,
                    msn_credits
                } = data.data;

                this.credits = credits;
                this.premiumCredits = premium_credits;
                this.financialCredits = financial_credits;
                this.msnCredits = msn_credits;

                const needsAuthoringCreditAndHasReceived = credits > 0;

                const needsPremiumAndHasReceived =
                    !this.request.is_premium ||
                    (this.request.is_premium && premium_credits > 0);

                const needsFinancialAndHasReceived =
                    !this.request.is_financial ||
                    (this.request.is_financial && financial_credits > 0);

                const needsMsnAndHasReceived =
                    !this.request.is_msn ||
                    (this.request.is_msn && msn_credits > 0);

                if (
                    needsAuthoringCreditAndHasReceived &&
                    needsPremiumAndHasReceived &&
                    needsFinancialAndHasReceived &&
                    needsMsnAndHasReceived
                ) {
                    if (this.checkPaymentInterval) {
                        clearInterval(this.checkPaymentInterval);
                    }

                    this.checkingPayment = false;

                    this.$store.dispatch('loading/hide');

                    this.$store.dispatch(
                        'notification/success',
                        'Payment received. You may submit your request'
                    );
                }
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    dataRequiredForPremium() {
        if (
            this.request.is_premium &&
            !this.$refs.getStartedStep.companyControl.selectedCompany?.phone
        ) {
            this.$refs.stepper.currentStep = this.steps[0];

            this.$refs.getStartedStep.companyControl.show = true;

            this.$nextTick(() => {
                this.$nextTick(() => {
                    this.$refs.getStartedStep.validateCompanyEditor();
                });
            });
        }
    }

    beforeRouteLeave(_to: Route, _from: Route, next: NavigationGuardNext) {
        if (
            this.currentStep.step === 'getStarted' &&
            this.$refs.getStartedStep.hasChanged
        ) {
            this.$refs.confirmDialog.show().then(discarded => {
                if (discarded) {
                    next();
                }
            });
        } else {
            next();
        }
    }

    setRequest(request: AuthorRequestData) {
        this.request = request;

        this.request.description =
            typeof request.description === 'string'
                ? JSON.parse(request.description)
                : request.description;
    }

    onFinalStep(data: { redirectUrl?: string }) {
        if (this.purchaseCreditsNecessaryToContinue && data.redirectUrl) {
            location.href = data.redirectUrl;
        } else {
            this.$router.push('/author-requests/success');
        }
    }
}
</script>
