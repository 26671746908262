<template>
    <v-dialog v-model="dialog" max-width="640px" persistent>
        <v-card>
            <v-card-title>{{ title }}</v-card-title>

            <v-card-text>
                <slot></slot>
            </v-card-text>

            <v-card-actions class="pt-0 px-6 pb-5">
                <v-btn text x-large @click.prevent="cancel">Discard</v-btn>

                <v-spacer />

                <v-btn color="primary" x-large @click.prevent="dialog = false">
                    {{ continueButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const ConfirmDialogProps = Vue.extend({
    props: {
        title: {
            type: String,
            default() {
                return 'Confirm';
            }
        },
        continueButtonText: {
            type: String,
            default() {
                return 'Continue';
            }
        }
    }
});

@Component
export default class ConfirmDialog extends ConfirmDialogProps {
    dialog = false;

    resolvePromise: unknown = null;

    show() {
        return new Promise(resolve => {
            this.dialog = true;

            this.resolvePromise = (flag: boolean) => {
                resolve(flag);
            };
        });
    }

    cancel() {
        if (typeof this.resolvePromise === 'function') {
            this.resolvePromise(true);
        }
    }
}
</script>
