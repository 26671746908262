<template>
    <v-container class="wizard__content--item-container">
        <a-form :submit="submit">
            <stepper-row>
                <template #input>
                    <v-row>
                        <v-col md="6">
                            <a-select-input
                                v-model="requestData.category_id"
                                rules="required"
                                label="Category"
                                :items="categories"
                                item-text="name"
                                item-value="id"
                                placeholder="Select a category"
                                :disabled="loading"
                            />
                        </v-col>
                    </v-row>
                </template>
            </stepper-row>

            <stepper-row>
                <template #input>
                    <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="This field"
                    >
                        <strong>
                            How often should we create an Amp for you?
                        </strong>

                        <v-radio-group
                            v-model="isRecurring"
                            :error-messages="errors"
                        >
                            <v-radio
                                key="request-recurring-2"
                                label="Just Once"
                                :value="false"
                            />

                            <v-radio
                                key="request-recurring-1"
                                label="Recurring"
                                :value="true"
                            />
                        </v-radio-group>
                    </validation-provider>
                </template>

                <template #info>
                    <a-alert
                        type="info"
                        data-alert="alert-first"
                        data-type="info"
                    >
                        <p class="mb-0">
                            <b>
                                With a recurring campaign, we'll distribute
                                {{ ampCountText }}
                                fresh Amp{{ ampCountText > 1 ? 's' : '' }} each
                                {{ recurrenceText }}.
                            </b>
                            Recurring campaigns will deduct
                            <b>
                                {{ ampCountText }} DFY credit{{
                                    ampCountText > 1 ? 's' : ''
                                }}
                            </b>
                            from your account every
                            {{ recurrenceText }} when we write a new Amp for
                            you.
                        </p>
                    </a-alert>
                </template>
            </stepper-row>

            <stepper-row v-if="isRecurring">
                <template #input>
                    <validation-provider rules="required">
                        <strong>
                            Create a new Done For You Amp in this Request every:
                        </strong>

                        <v-radio-group
                            v-model="occurrence"
                            class="occurence-options"
                        >
                            <v-radio
                                label="Day"
                                value="day"
                                :disabled="!canSelectCustomFrequency"
                            />

                            <v-radio
                                label="Week"
                                value="week"
                                :disabled="!canSelectCustomFrequency"
                            />

                            <v-radio label="Month" value="month" />

                            <v-radio
                                value="other"
                                :disabled="!canSelectOtherFrequency"
                                class="align-start align-sm-center"
                            >
                                <template #label>
                                    <div
                                        class="d-flex flex-column flex-sm-row align-sm-center justify-sm-start flex-wrap flex-sm-nowrap"
                                    >
                                        <span class="mr-2 mb-2 mb-sm-0">
                                            Other
                                        </span>

                                        <a-select-input
                                            v-model="frequency.ampCount"
                                            dense
                                            :items="ampCountFrequency"
                                            hide-details
                                            class="frequency-selector"
                                        />

                                        <span
                                            class="mx-0 mx-sm-2 my-2 my-sm-0"
                                            style="white-space: nowrap"
                                        >
                                            Amps per
                                        </span>

                                        <a-select-input
                                            v-model="frequency.occurrence"
                                            dense
                                            :items="['week', 'month']"
                                            hide-details
                                            class="frequency-selector"
                                        />
                                    </div>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </validation-provider>
                </template>
            </stepper-row>

            <stepper-row>
                <template #input>
                    <strong>Include distribution to authority sites</strong>

                    <v-checkbox
                        v-model="requestData.is_premium"
                        label="Premium Distribution"
                        hide-details
                        @change="$emit('premiumChange')"
                    />

                    <v-checkbox
                        v-model="requestData.is_financial"
                        label="Financial Distribution"
                        hide-details
                        class="mt-2"
                    />

                    <v-checkbox
                        v-model="requestData.is_msn"
                        label="Microsoft MSN Distribution"
                        hide-details
                        class="mt-2"
                    />
                </template>

                <template #info>
                    <a-alert type="info" data-type="info">
                        <p class="mb-0">
                            Premium Distribution adds publishing on
                            world-renowned news brands Business Insider, Medium,
                            and others.
                        </p>

                        <p class="mb-0">
                            <b>
                                You have
                                {{ premiumCredits || 'no' }} Premium
                                Distribution Credits
                            </b>
                        </p>
                    </a-alert>

                    <a-alert type="info" data-type="info">
                        <p class="mb-0">
                            Financial Distribution adds publishing on Yahoo
                            Finance and others.
                        </p>

                        <p class="mb-0">
                            <b>
                                You have
                                {{ financialCredits || 'no' }} Financial
                                Distribution Credits
                            </b>
                        </p>
                    </a-alert>

                    <a-alert type="info" data-type="info">
                        <p class="mb-0">
                            Microsoft MSN Distribution adds publishing on MSN.
                        </p>

                        <p class="mb-0">
                            <b>
                                You have
                                {{ msnCredits }} Microsoft MSN Distribution
                                Credits
                            </b>
                        </p>
                    </a-alert>
                </template>
            </stepper-row>

            <stepper-actions
                :next-button-text="nextButtonText"
                :show-next-arrow-icon="false"
                :disabled="checkingPayment"
                @prev="$emit('prev')"
            >
                <template #info>
                    <a-alert
                        data-type="info"
                        :type="credits ? 'success' : 'warning'"
                        :message="dfyCreditsInfo"
                    />
                    <a-alert
                        v-if="
                            purchaseCreditsNecessaryToContinue ||
                            checkingPayment
                        "
                        class="mt-8 mb-0"
                        data-type="info"
                        type="warning"
                        :message="creditsInfo"
                    />
                </template>
            </stepper-actions>
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { InjectReactive, Watch } from '@/utils/decorators';

import { InfoFieldMixin } from '@/mixins';

import { AAlert } from '@/components/AAlert';
import { AForm } from '@/components/AForm';
import { StepperActions, StepperRow } from '@/components/Stepper';
import { ASelectInput } from '@/components/AForm/Inputs/ASelectInput';

import type { Category } from '@/types/Category';

import {
    AuthorRequestFrequencyFactory,
    Occurrence
} from '@/components/Authoring/AuthorRequestFrequency';

import type { AuthorRequestData } from '@/types/AuthorRequest';

const FinishingUpProps = Vue.extend({
    props: {
        categories: {
            type: Array as PropType<Category[]>,
            default() {
                return [];
            }
        },
        credits: {
            type: Number,
            default() {
                return 0;
            }
        },
        premiumCredits: {
            type: Number,
            default() {
                return 0;
            }
        },
        financialCredits: {
            type: Number,
            default() {
                return 0;
            }
        },
        msnCredits: {
            type: Number,
            default() {
                return 0;
            }
        },
        checkingPayment: {
            type: Boolean,
            default() {
                return false;
            }
        },
        isActive: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AForm,
        AAlert,
        StepperActions,
        StepperRow,
        ASelectInput
    }
})
export default class FinishingUp extends mixins(
    FinishingUpProps,
    InfoFieldMixin
) {
    @InjectReactive({
        from: 'requestData',
        default() {
            return {};
        }
    })
    requestData!: AuthorRequestData;

    AuthorRequestFrequency = new AuthorRequestFrequencyFactory();

    isRecurring: boolean | null = null;

    @Watch('isRecurring')
    onIsRecurringChange(isRecurring: boolean) {
        if (isRecurring) {
            this.setRequestFrequency(this.occurrence);
        } else {
            this.requestData.frequency = null;
        }

        this.onRender();
    }

    occurrence = 'month';

    @Watch('occurrence')
    onRecurrenceChange(occurrence: string) {
        this.setRequestFrequency(occurrence);
    }

    @Watch('isActive')
    onActivate() {
        this.onRender();
    }

    frequency: { ampCount: number; occurrence: Occurrence } = {
        ampCount: 2,
        occurrence: 'month'
    };

    @Watch('frequency', { deep: true })
    onFrequencyChange() {
        this.setRequestFrequency(this.occurrence);
    }

    get loading() {
        return this.$store.state.loading.show;
    }

    get nextButtonText() {
        if (this.checkingPayment) {
            return 'Processing Payment...';
        }

        return this.purchaseCreditsNecessaryToContinue
            ? 'Checkout'
            : this.$vuetify.breakpoint.xsOnly
              ? 'Submit'
              : 'Submit using Authoring Credits';
    }

    get purchaseCreditsNecessaryToContinue() {
        return (
            !this.credits ||
            (this.requestData.is_premium && !this.premiumCredits) ||
            (this.requestData.is_financial && !this.financialCredits) ||
            (this.requestData.is_msn && !this.msnCredits)
        );
    }

    get creditsInfo() {
        if (this.checkingPayment) {
            return '<b>This page will update automatically once payment has been received.</b>';
        }

        if (this.purchaseCreditsNecessaryToContinue) {
            return '<b>You will be prompted to purchase credits before submission</b>';
        }

        return '';
    }

    get dfyCreditsInfo() {
        if (this.credits) {
            return `<b>You currently have ${this.credits} DFY credits.</b> Requesting a professionally-written Amp will deduct <b>1 DFY credit</b> from your account.`;
        }

        return '<b>You currently have no DFY credits.</b> You will have to purchase an DFY credit to submit this request.';
    }

    get recurrenceText() {
        if (this.occurrence === 'other') {
            return this.frequency.occurrence;
        }

        return this.occurrence;
    }

    get ampCountText() {
        if (this.occurrence === 'other') {
            return this.frequency.ampCount;
        }

        return 1;
    }

    get canSelectCustomFrequency() {
        return this.credits > 0;
    }

    get canSelectOtherFrequency() {
        return this.credits > 1;
    }

    get ampCountFrequency() {
        return [2, 3, 4, 5, 6];
    }

    onRender() {
        this.$nextTick(() => {
            this.fitInfosFor('first');
        });
    }

    submit() {
        this.$emit('next');
    }

    setRequestFrequency(occurrence: string) {
        let frequency: number;

        if (occurrence !== 'other') {
            frequency = this.AuthorRequestFrequency.getFrequency(
                1,
                occurrence as Occurrence
            );
        } else {
            frequency = this.AuthorRequestFrequency.getFrequency(
                this.frequency.ampCount,
                this.frequency.occurrence
            );
        }

        this.requestData.frequency = frequency;
    }

    mounted() {
        this.requestData.frequency = null;
        this.onRender();
    }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .gap {
        height: 20px;
    }
    .v-size--x-large {
        padding: 5px !important;
    }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
    .gap {
        height: 30px;
    }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
    .gap {
        height: 0px;
    }
}

.occurence-options {
    .v-radio--is-disabled {
        opacity: 0.5;
    }
}
</style>
