import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":"640px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.title))]),_c(VCardText,[_vm._t("default")],2),_c(VCardActions,{staticClass:"pt-0 px-6 pb-5"},[_c(VBtn,{attrs:{"text":"","x-large":""},on:{"click":function($event){$event.preventDefault();return _vm.cancel.apply(null, arguments)}}},[_vm._v("Discard")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","x-large":""},on:{"click":function($event){$event.preventDefault();_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.continueButtonText)+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }