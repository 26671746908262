<template>
    <v-container fluid class="wizard__content--item-container">
        <a-form :submit="submit">
            <stepper-row>
                <template #input>
                    <h6 class="text-h6 mb-8 d-md-none">Media</h6>
                    <media-preview-list
                        :media-resources="mediaResources"
                        :user-id="requestData.user_id"
                        :options="mediaOptions"
                        class="mb-4"
                        module-title="Author Request"
                        @mediaFiles="onMediaFilesUpdate"
                    />

                    <validation-provider
                        v-slot="{ errors }"
                        name="Media Resources"
                        rules="required|imageMediaResourceLength:5"
                        :skip-if-empty="false"
                    >
                        <input v-model="mediaResources" type="hidden" />

                        <span
                            v-show="errors.length > 0"
                            class="v-messages v-messages__message error--text"
                        >
                            Please provide at least 5 images
                        </span>
                    </validation-provider>
                </template>

                <template #info>
                    <a-alert type="info">
                        <p class="mb-0">
                            To ensure maximum media distribution and help our
                            writers represent your brand as faithfully as
                            possible, please upload at least 1 brand-related
                            images that are relevant to your request.
                        </p>
                    </a-alert>
                </template>
            </stepper-row>

            <stepper-actions @prev="$emit('prev')" @next="$emit('next')" />
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { InjectReactive, Watch } from '@/utils/decorators';

import { InfoFieldMixin } from '@/mixins';

import { AAlert } from '@/components/AAlert';
import { AForm } from '@/components/AForm';
import { MediaPreviewList } from '@/components/Media';
import { StepperActions, StepperRow } from '@/components/Stepper';

import type { FormMediaResource } from '@/types/Media';
import type { AuthorRequestData } from '@/types/AuthorRequest';

const MediaProps = Vue.extend({
    props: {
        submitting: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: { MediaPreviewList, AAlert, AForm, StepperActions, StepperRow }
})
export default class Media extends mixins(InfoFieldMixin, MediaProps) {
    @InjectReactive({
        from: 'requestData',
        default() {
            return {};
        }
    })
    requestData!: AuthorRequestData;

    mediaResources: FormMediaResource[] = [];

    @Watch('mediaResources')
    onMediaResourcesChange(mediaResources: FormMediaResource[]) {
        this.requestData.media_resources = mediaResources;
    }

    get mediaOptions() {
        return {
            author_request_id: this.requestData.id
        };
    }

    mounted() {
        this.mediaResources = this.requestData.media_resources
            ? this.requestData.media_resources
            : [];
    }

    submit() {
        this.$emit('next');
    }

    onMediaFilesUpdate(resources: FormMediaResource[]) {
        this.mediaResources = resources;
    }
}
</script>
